<template>
  <div class="result">
      <img src="../assets/img/resultRealOk.png">
      <p>产品认购完成，请注意查收短信，点击短信中的链接，进行电子合同签章，完成最终签约！</p>
      <div class="resultReal">
        <router-link :to="{path:'signList'}" class="button">查看签约信息</router-link>
      </div>
      <p class="tips">如有疑问可联系您的理财经理</p>
  </div >
</template>
<script>
  export default {
    data() {
      return {
      };
    },
    created: function () {
    },
    methods: {
     
    }
  };
</script>

<style lang="scss">
.result{
  text-align: center;
   p{ line-height: 2rem;margin: 2rem;font-size:1rem}
   .tips{color:#999;font-size: 0.8rem;}
   img{width: 20%;margin-top: 8rem;}
   .resultReal{
     margin-top:3rem;
     a {
      display: inline-block;
      width: 40%;
      margin-left: 10px;
      margin-right: 10px;
      height: 2.5rem;
      line-height: 2.5rem;
    }
   }
}
</style>
